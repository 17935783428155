<template>
    <div id="ItemContainer" class="margintop">
        <div id="TopbarContainer">
            <h1 id="Title" class="text-left">Sobre o Projeto CLeg</h1>
        </div>
        <div id="ItemDisplay" class="about-cleg">
            <div v-html="content"></div>
        </div>
    </div>
</template>


<script>

export default {
    name: "Sobre",
    components: {
    },
    data: () => {
        return {
            content: ""
        };
    },
    methods: {
        getSobreAtual: async function () {
            await this.$http.get("/SaibaMais/GetSobreAtual")
                .then(result => {
                    this.content = result.data.texto;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
        }
    },
    mounted: function () {
        this.getSobreAtual();
    }
}

</script>
<style>
@import "../About.css";
</style>